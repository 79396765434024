<template>
  <div style="position:relative;">
    <div
      cols="12"
      class="d-flex"
      style="position:relative;text-align:center;padding-top:20px;"
    >
      <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
        STRUKTUR JABATAN
      </h6>
      <v-btn
        type="button"
        rounded
        elevation="0"
        color="red"
        class="white--text font-weight-bold ma-2"
        style="font-size:12px; position:absolute; top:0; right:10px; z-index:2;"
        @click.prevent="close"
        :loading="loading"
        outlined
      >
        <v-icon small>mdi-chevron-left</v-icon>
        Kembali
      </v-btn>
    </div>
    <div class="d-flex justify-content-between align-center">
      <div style="width: 160px;margin-left:20px;">
        <v-select
          label="Department"
          v-model="paramAPI.department_id"
          :items="dropdown.department"
          style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
          item-text="name"
          item-value="id"
          return-id
          dense
          outlined
        >
        </v-select>
      </div>
    </div>
    <div v-if="result !== null">
      <organization-chart
        v-if="result !== undefined"
        :datasource="result"
        style="height:90vh;width:98.9vw;border:none;"
      >
        <template slot-scope="{ nodeData }">
          <div @click="nodeClick(nodeData.id)">
            <div
              style="background:blue; color:white; border:1px solid black; fontSize:14px; font-weight:bold; border-radius: 5px 5px 0 0;"
            >
              {{ nodeData.name }}
            </div>
            <div
              style="border:1px solid black; fontSize:12px; text-overflow:ellipsis; white-space: nowrap; overflow: hidden;"
            >
              {{ nodeData.title }}
            </div>
          </div>
        </template>
      </organization-chart>
    </div>
    <div
      v-if="result === null"
      style="width:100%; height:60vh;"
      class="d-flex justify-center align-center"
    >
      <div style="margin: auto; font-size:18px; font-weight:bold;">
        DATA NOT FOUND
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import axios from 'axios'
import buildType from '../../../services/buildType'

export default {
  name: 'hierarchyposition',
  components: {
    OrganizationChart
  },
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      build: process.env.VUE_APP_BUILD_TYPE,
      paramAPI: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        status: '',
        sortBy: 'name',
        sortType: 'asc',
        company_id: 0,
        department_id: '43',
        itemsPerPage: 10
      },
      loading: false,
      totalData: 0,
      userId: 3,
      result: null,
      dropdown: {
        company: [],
        department: []
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  async mounted() {
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )

    await this.getDataFromApi()
    await this.companyDropdown()
    await this.departmentDropdown()
  },
  watch: {
    'paramAPI.department_id'() {
      this.getDataFromApi()
    }
  },
  methods: {
    ...mapActions(['dropdownCompany', 'dropdownDepartment']),
    nodeClick(item) {
      this.paramAPI.department_id = `${item}`
      this.getDataFromApi()
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then(data => {
        // setTimeout(() => {
        this.result = data.data
        // this.totalData = data.total_record
        this.loading = false
        // }, 1000)
      })
    },
    async initDataTable() {
      return await new Promise(resolve => {
        axios
          .get(
            `${this.hrsApi}employee_department/hierarchy?id=${this.paramAPI.department_id}`
          )
          .then(res => {
            console.log(res)
            if (res.data.status_code === '-99') {
              resolve({ data: null })
              return
            }
            resolve(res.data)
          })
          .catch(err => {
            this.result = null
            console.log(err)
          })
      })
    },
    async companyDropdown() {
      await this.dropdownCompany()
        .then(res => {
          console.log(res)
          if (res.status_code === '00') {
            this.dropdown.company = res.data
          } else {
            this.dropdown.company = []
          }
          return null
        })
        .catch(err => {
          console.log(err)
          this.dropdown.company = []
          return null
        })
    },
    async departmentDropdown() {
      this.department = [{ id: '', name: 'All' }]
      this.dropdownDepartment()
        .then(res => {
          if (res.status_code === '00') {
            for (let i = 0; i < res.data.length; i++) {
              this.dropdown.department.push(res.data[i])
            }
          } else {
            this.dropdown.department = []
          }
          return null
        })
        .catch(err => {
          console.log(err)
          this.dropdown.department = []
          return null
        })
    },

    close() {
      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path === '/hr/ms/position_hierarchy') {
          this.$router.push(this.prevRoute.path)
        } else {
          this.$router.push('/hr/dashboard')
        }
      } else {
        this.$router.push('/hr/dashboard')
      }
    }
  }
}
</script>
<style scoped>
#canvas {
  border: 1px solid black;
}
</style>
